import React, { useRef, useEffect } from "react"
import lottie from "lottie-web"
import Fade from "react-reveal/Fade"
import { useInView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import helsinkiAnimatedImage from "../../../anim-files/helsinki-icon.json"
import stockholmAnimatedImage from "../../../anim-files/stockholm-icon.json"
import "./_index.scss"

const AddressSection = () => {
  const data = useStaticQuery(graphql`
    {
      helsinkiAddress: contentfulContactAddress(name: { eq: "Helsinki" }) {
        name
        address
        phone
        email
      }
      stockholmAddress: contentfulContactAddress(name: { eq: "Stockholm" }) {
        name
        address
        phone
        email
      }
    }
  `)

  const helsinkiAddress = data.helsinkiAddress
  const stockholmAddress = data.stockholmAddress

  const [ref, inView] = useInView({
    threshold: 0,
  })

  let helsinkiAnimationContainer = useRef()
  let stockholmAnimationContainer = useRef()

  useEffect(() => {
    if (!helsinkiAnimationContainer && !stockholmAnimationContainer) {
      return
    }

    const helAnim = lottie.loadAnimation({
      container: helsinkiAnimationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: helsinkiAnimatedImage,
    })

    const stockAnim = lottie.loadAnimation({
      container: stockholmAnimationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: stockholmAnimatedImage,
    })

    if (inView === true) {
      stockAnim.play()
      helAnim.play()
    }

    return () => {
      helAnim.destroy()
      stockAnim.destroy()
    }
  }, [helsinkiAnimationContainer, stockholmAnimationContainer, inView])

  return (
    <section id="contact-addresses-section">
      <Container>
        <Row>
          <Col md={6} className="text-center address-col" ref={ref}>
            <div
              className="animated-container"
              ref={helsinkiAnimationContainer}
            />
            <Fade cascade duration={2500}>
              <h2 dangerouslySetInnerHTML={{ __html: helsinkiAddress.name }} />
              <p
                dangerouslySetInnerHTML={{ __html: helsinkiAddress.address }}
              />
              <a
                href={`tel:${helsinkiAddress.phone}`}
                dangerouslySetInnerHTML={{ __html: helsinkiAddress.phone }}
              />
              <br />
              <a
                href={`mailto:${helsinkiAddress.email}`}
                className=""
                dangerouslySetInnerHTML={{ __html: helsinkiAddress.email }}
              />
            </Fade>
          </Col>

          <Col md={6} className="text-center address-col">
            <div
              className="animated-container"
              ref={stockholmAnimationContainer}
            />
            <Fade cascade duration={2500}>
              <h2 dangerouslySetInnerHTML={{ __html: stockholmAddress.name }} />
              <p
                dangerouslySetInnerHTML={{ __html: stockholmAddress.address }}
              />
              <a
                href={`tel:${stockholmAddress.phone}`}
                dangerouslySetInnerHTML={{ __html: stockholmAddress.phone }}
              />
              <br />
              <a
                href={`tel:${stockholmAddress.email}`}
                dangerouslySetInnerHTML={{ __html: stockholmAddress.email }}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AddressSection
