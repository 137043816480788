import React from "react"
import * as Yup from "yup"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Formik, Field, Form } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  phone: Yup.string().required("Phone Number is Required"),
})

const initialValues = {
  "bot-field": "",
  "form-name": "contact-form",
  name: "",
  email: "",
  phone: "",
  message: "",
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const FormSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulContactPage {
        nodes {
          text
        }
      }
    }
  `)

  const contactData = data.allContentfulContactPage.nodes[0]

  const handleSubmit = payload => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...payload }),
    }).catch(error => alert(error))
  }

  return (
    <section id="contact-form-section">
      <Container>
        <Row>
          <Col lg={7} md={6} className="contact-text-sec">
            <Fade cascade duration={2500}>
              <h1 dangerouslySetInnerHTML={{ __html: contactData.text }} />
            </Fade>
          </Col>

          <Col lg={4} md={6} className="contact-form-sec offset-lg-1">
            <Formik
              initialValues={initialValues}
              name="contact-form"
              validationSchema={ContactSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Fade cascade duration={2500}>
                  <Form
                    data-netlify="true"
                    name="contact-form"
                    data-netlify-honeypot="bot-field"
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="contact-form"
                    />

                    <div className="form-group">
                      <Field
                        name="name"
                        type="text"
                        placeholder="Name"
                        className={
                          errors.name && touched.name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {errors.name && touched.name ? (
                        <div className="invalid-feedback">{errors.name}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                        className={
                          errors.email && touched.email
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        name="phone"
                        type="text"
                        placeholder="Phone number"
                        className={
                          errors.phone && touched.phone
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {errors.phone && touched.phone ? (
                        <div className="invalid-feedback">{errors.phone}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        name="message"
                        component="textarea"
                        placeholder="Message"
                        rows="5"
                        className="form-control"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn submit-btn"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Sending" : "Send"}
                    </button>
                  </Form>
                </Fade>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FormSection
