import React from "react"
import FormSection from "./form-section"
import AddressSection from "./address-section"

const ContactUs = () => {
  return (
    <>
      <FormSection />
      <AddressSection />
    </>
  )
}

export default ContactUs
