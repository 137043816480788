import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUs from "../components/contact-us"

const DomainServicesPage = ({ data }) => {
  const metaData = data.allContentfulContactPage.nodes[0]

  return (
    <Layout>
      <SEO title="Contact us" description={metaData.metaDescription} />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulContactPage {
      nodes {
        metaDescription
      }
    }
  }
`

export default DomainServicesPage
